<template>
  <div>
    <CRow v-if="showLoader" class="justify-content-center">
      <Loader
        width="100px"
        height="100px"
        fill-color="#c2c2c2"
        cssClass="fadeIn anim-speed--1s"
      />
    </CRow>
    <TableIncidentNfit v-if="!showLoader" :tableData="tableData" />
  </div>
</template>

<script>
import Api from "@/services/Api";
import Loader from "@/components/atoms/Loader";

const TableIncidentNfit = () =>
  import(
    /* webpackChunkName: "table-incident-NFIT" */ "@/components/organisms/TableIncidentNfit"
  );
export default {
  name: "incidentNfit",
  components: {
    TableIncidentNfit,
    Loader,
  },
  data() {
    return {
      lang: null,
      messages: null,
      showLoader: true,
      tableData: [],
    };
  },
  methods: {
    async makeApiQuery() {
      const results = await Api.sendGet("marketing/incidentNfit");
      return results.data.data;
    },
    processTableData() {
      this.makeApiQuery().then((rawData) => {
        this.tableData = rawData.map((element) => {
          return {
            ref: element.ref,
            nfitId: element.nfitId,
            nfitDate: element.nfitDate,
            status: element.status,
            statusDate: element.statusDate,
            _classes: "focus",
          };
        });
        this.showLoader = false;
      });
    },
  },

  created() {
    this.lang = this.$store.getters.getCurrentLang;
    this.messages = this.$store.getters.getLangMessages;
    this.processTableData();
  },
};
</script>

<style></style>
